import { useState } from 'react';
import './edit-message.styles.css';
import { useNavigate } from 'react-router-dom';

import MessageService from '../../services/message.service';
import { authProvider } from '../../auth.provider';
import { toast } from 'react-toastify';

function EditMessageDialog(props: any) {

    const navigate = useNavigate();

    const [title, setTitle] = useState<string>(props.message.title);
    const [body, setBody] = useState<string>(props.message.body);

    /**
     * Closes the dialog with the signal that the parent
     * needs to update the message references.
     * @param needRefresh determines that the parent component needs a refresh or not
     */
    function close(needRefresh: boolean): void {
        props.onClose(needRefresh);
    }

    /**
     * Updates the message with the given settings
     * and closes the dialog with refresh signal.
     */
    function updateAndClose(): void {
        MessageService.updateFields(props.message._id, { title, body })
            .then(() => close(true))
            .catch((err) => {
                if (err == 'A munkamenet lejárt!') {
                    toast.error(err);
                    setTimeout(() => {
                        authProvider.signout();
                        navigate('/login');
                    }, 2000);
                    return;
                }

                if (err.error) {
                    toast.error(err.error);
                    return;
                }

                if (err.status) {
                    toast.error(err.status);
                    return;
                }
            });
    }

    return (
        <div id="edit-message-dialog">
            <div id="edit-message-dialog-content" className='fade-in-up light-shadow'>
                <h3 id='edit-message-title'>Üzenet szerkesztése</h3>

                <div id='edit-message-form'>
                    <div>
                        <label className='form-item-label'>Cím</label>
                        <input value={title} onChange={(_event: any) => setTitle(_event.target.value)} placeholder='Az üzenet címe' className='send-message-input' />
                    </div>

                    <div>
                        <label className='form-item-label'>Törzs</label>
                        <textarea value={body} onChange={(_event: any) => setBody(_event.target.value)} className='send-message-input' placeholder='Az üzenet leírása'></textarea>
                    </div>
                </div>

                <div id='edit-message-buttons'>
                    <button onClick={() => updateAndClose()} className='primary-button'>Mentés</button>
                    <button onClick={() => close(false)}>Mégsem</button>
                </div>
            </div>
        </div>
    );
}

export default EditMessageDialog;